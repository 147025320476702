import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-abilene-texas.png'
import image0 from "../../images/cities/scale-model-of-abilene-zoological-park-in-abilene-texas.png"
import image1 from "../../images/cities/scale-model-of-the-grace-museum-in-abilene-texas.png"
import image2 from "../../images/cities/scale-model-of-12th-armored-division-memorial-in-abilene-texas.png"
import image3 from "../../images/cities/scale-model-of-national-center-for-children-in-abilene-texas.png"
import image4 from "../../images/cities/scale-model-of-tourist-information-center-in-abilene-texas.png"
import image5 from "../../images/cities/scale-model-of-abandoned-school-in-abilene-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Abilene'
            state='Texas'
            image={image}
            lat='32.4487364'
            lon='-99.73314390000002'
            attractions={ [{"name": "Abilene Zoological Park", "vicinity": "2070 Zoo Ln, Abilene", "types": ["zoo", "point_of_interest", "establishment"], "lat": 32.4392027, "lng": -99.69094180000002}, {"name": "The Grace Museum", "vicinity": "102 Cypress St, Abilene", "types": ["museum", "point_of_interest", "establishment"], "lat": 32.4496457, "lng": -99.73445859999998}, {"name": "12th Armored Division Memorial", "vicinity": "1289 N 2nd St, Abilene", "types": ["museum", "point_of_interest", "establishment"], "lat": 32.450401, "lng": -99.736426}, {"name": "National Center for Children", "vicinity": "102 Cedar St, Abilene", "types": ["museum", "point_of_interest", "establishment"], "lat": 32.4499178, "lng": -99.73574230000003}, {"name": "Tourist Information Center", "vicinity": "1101 N 1st St, Abilene", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 32.4492908, "lng": -99.7345707}, {"name": "Abandoned School", "vicinity": "1952, 2002 Jameson St, Abilene", "types": ["point_of_interest", "establishment"], "lat": 32.4888799, "lng": -99.74377370000002}] }
            attractionImages={ {"Abilene Zoological Park":image0,"The Grace Museum":image1,"12th Armored Division Memorial":image2,"National Center for Children":image3,"Tourist Information Center":image4,"Abandoned School":image5,} }
       />);
  }
}